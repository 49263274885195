<template>
  <layout-sub name="risk">
    <p class="p-risk-text">
      建设全面风险控制体系，打造募、投、管、退的全流程风险控制措施，实现公司、团队、基金利益深度捆绑。
    </p>
    <b-row class="p-risk-row mx-0">
      <b-col cols="12" md="6" class="p-risk-col px-0 mt-3">
        <img src="@/assets/img-risk-1.png" />
      </b-col>
      <b-col cols="12" md="6" class="p-risk-col px-0 mt-3">
        <img src="@/assets/img-risk-2.png" />
      </b-col>
      <b-col cols="12" md="6" class="p-risk-col px-0 mt-3">
        <img src="@/assets/img-risk-3.png" />
      </b-col>
      <b-col cols="12" md="6" class="p-risk-col px-0 mt-3">
        <img src="@/assets/img-risk-4.png" />
      </b-col>
      <b-col cols="12" md="6" class="p-risk-col px-0 mt-3">
        <img src="@/assets/img-risk-5.png" />
      </b-col>
      <b-col cols="12" md="6" class="p-risk-col px-0 mt-3">
        <img src="@/assets/img-risk-6.png" />
      </b-col>
    </b-row>
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";

export default {
  name: "Risk",
  components: { LayoutSub },
};
</script>

<style lang="scss" scoped>
.p-risk {
  &-row {
    justify-content: space-evenly;
  }

  &-col {
    border-radius: 50px;
    overflow: hidden;
    text-align: center;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    &-col {
      position: relative;
      height: 200px;
      border: 4px solid #fff;
      border-radius: 30px;

      img {
        position: absolute;
        left: 0;
        bottom: -80px;
        width: auto;
        height: 320px;
        transition: bottom 0.3s;
      }

      &:hover {
        img {
          bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &-text {
      font-size: $font-size-lg;
    }
  }

  @media (min-width: 1550px) {
    &-col {
      height: 330px;

      img {
        bottom: -110px;
        height: 440px;
      }
    }
  }
}
</style>
